import React, {Component} from 'react';

class Terms extends Component {
	render() {
		return(
			<section id="terms">
				<iframe src="/dl/privacy.pdf" title="Polityka prywatności" />
			</section>
		);
	}
}

export default Terms;