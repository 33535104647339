const MapsConfig = {
	office: {
		center: {
			lat: 49.71743143963062,
			lng: 18.802684097489742
		}
	},
	center: {
		lat: 49.71743143963062,
		lng: 18.802684097489742
	},
	zoom: 11
};

export default MapsConfig;